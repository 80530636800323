@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);
@import url(https://fonts.googleapis.com/css?family=Iceland:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);
html, body, html * {
  font-family: 'Roboto', sans-serif;
}


body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
      margin-left: 0;
  }

  #page-content-wrapper {
      min-width: 0;
      width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
      margin-left: -15rem;
  }
}

.vhmax-10 { max-height: 10vh!important; }
.vhmax-20 { max-height: 20vh!important; }
.vhmax-30 { max-height: 30vh!important; }
.vhmax-40 { max-height: 40vh!important; }
.vhmax-50 { max-height: 50vh!important; }
.vhmax-60 { max-height: 60vh!important; }
.vhmax-70 { max-height: 70vh!important; }
.vhmax-80 { max-height: 80vh!important; }
.vhmax-90 { max-height: 90vh!important; }

#my-toast{
  -webkit-box-shadow: 0px 0px 100px 20px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 100px 20px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 100px 20px rgba(0,0,0,0.75);
}

.info-bg{ background-color: #f0f8ff!important }
.card-body-bg{ background-color:#f0f8ff!important }

#gamepage-sponsored-img{ max-width: '100%'; margin: 10px;}

.card-width{ width: '20rem'!important; }
.w150{ width: 150px; }
.score{        font-size: 120px; color: #fff; font-family: Iceland; animation: neon2 1.5s ease-in-out infinite alternate;}
.score-hyphen{ font-size: 100px; color: #fff; font-family: Iceland; animation: neon2 1.5s ease-in-out infinite alternate;}


@media (max-width: 768px) {
  .card-width  { width: '100%'!important; }
  #navbar-logo { margin-bottom: 10px; }
  .score       { font-size: 60px; }
  .score-hyphen{ font-size: 50px; }
  
}


.card-token-symbol { background-color:'#2e9bb8'!important;}
.bg-c-blue   {background: linear-gradient(45deg,#b0d5ff ,#1e86ff);}
.bg-c-green  {background: linear-gradient(45deg,#81ffe5,#59e0c5);}
.bg-c-gray   {background: linear-gradient(45deg,#b8b8b8,#848484);}
.bg-c-yellow {background: linear-gradient(45deg,#ffd18e,#ffb242);}
.bg-c-pink   {background: linear-gradient(45deg,#ffa8b7,#ff607a);}


.filter-tag{
  background-color: transparent;
  color:#602f2f
}


/* particles */
canvas {
  display: block;
  vertical-align: bottom;
}

/* ---- particles.js container ---- */

#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  /*background-color: #ececec;*/
  background-image: url("");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  z-index: -9999;
}
/* particles */




/* body bg https://codepen.io/nikki-peel/pen/XWdVeRy */
/*
.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #24C6DC; 
  background: -webkit-linear-gradient(to bottom, #514A9D, #24C6DC);  
  background: linear-gradient(to bottom, #514A9D, #24C6DC); 
  z-index: -99;
}

.box div {
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: transparent;
  border: 6px solid rgba(255,255,255,0.8);
}

.box div:nth-child(1) {
  top: 12%;
  left: 42%;
  animation: animate 10s linear infinite;
}

.box div:nth-child(2) {
  top: 70%;
  left: 50%;
  animation: animate 7s linear infinite;
}
.box div:nth-child(3) {
  top: 17%;
  left: 6%;
  animation: animate 9s linear infinite;
}

.box div:nth-child(4) {
  top: 20%;
  left: 60%;
  animation: animate 10s linear infinite;
}

.box div:nth-child(5) {
  top: 67%;
  left: 10%;
  animation: animate 6s linear infinite;
}

.box div:nth-child(6) {
  top: 80%;
  left: 70%;
  animation: animate 12s linear infinite;
}

.box div:nth-child(7) {
  top: 60%;
  left: 80%;
  animation: animate 15s linear infinite;
}

.box div:nth-child(8) {
  top: 32%;
  left: 25%;
  animation: animate 16s linear infinite;
}

.box div:nth-child(9) {
  top: 90%;
  left: 25%;
  animation: animate 9s linear infinite;
}

.box div:nth-child(10) {
  top: 20%;
  left: 80%;
  animation: animate 5s linear infinite;
}

@keyframes animate {
  0% {
    transform: scale(0) translateY(-90px) rotate(360deg);
    opacity: 1;
  }
  
  100% {
    transform: scale(1.3) translateY(-90px) rotate(-180deg);
    border-radius: 50%;
    opacity: 0;
  }
}
*/

 /* Exp Time */

.glow {
  color: #fff;
  text-align: center;
  -webkit-animation: glow 0.5s ease-in-out infinite alternate;
  -moz-animation: glow 0.5s ease-in-out infinite alternate;
  animation: glow 0.5s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #f85e5e, 0 0 40px #f85e5e, 0 0 50px #f85e5e, 0 0 60px #f85e5e, 0 0 70px #f85e5e;
  }
  to {
    text-shadow: 0 0 20px #c7c7c7, 0 0 30px #916d6d, 0 0 40px #916d6d, 0 0 50px #916d6d, 0 0 60px #916d6d, 0 0 70px #916d6d, 0 0 80px #916d6d;
  }
}

.expired-text {
  color: #ff6347;
  text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #f85e5e, 0 0 40px #f85e5e, 0 0 50px #f85e5e, 0 0 60px #f85e5e, 0 0 70px #f85e5e;
}


@keyframes neon2 {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #228dff,
      0 0 70px #228dff, 0 0 80px #228dff, 0 0 100px #228dff, 0 0 150px #228dff;
  }
  to {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #228dff,
      0 0 35px #228dff, 0 0 40px #228dff, 0 0 50px #228dff, 0 0 75px #228dff;
  }
}
.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  color: yellow; 
}

@keyframes blink-animation {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

/* 
  D A S H B O A R D   2

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  min-height: 100vh;
}

#sidebar.active {
  margin-left: -250px;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

@media (max-width: 768px) {
  #sidebar {
      margin-left: -250px;
  }
  #sidebar.active {
      margin-left: 0;
  }
}


@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";


body {
    font-family: 'Poppins', sans-serif;
    background: #fafafa;
}

p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

#sidebar {
    background: #7386D5;
    color: #fff;
    transition: all 0.3s;
}

#sidebar .sidebar-header {
    padding: 20px;
    background: #6d7fcc;
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #47748b;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}
#sidebar ul li a:hover {
    color: #7386D5;
    background: #fff;
}

#sidebar ul li.active > a, a[aria-expanded="true"] {
    color: #fff;
    background: #6d7fcc;
}
ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
}
*/






/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/